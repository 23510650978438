import React from 'react';

function Error(){

    return (
        <div>
            <h6 class="center">The page you were trying to reach was not found.</h6>
        </div>

    );
}

export default Error;