import React from 'react';
import '../ryanStyle/map.css'


function Map(){

    return (
        <div>

        <iframe title="TravelMap"src="https://www.traveledmap.com/embedded-map/yH6kkJjuc8NtvExpPcDxiAGfu6S2?markersColor=hsl(215.55555555555554%2C%2049.090909090909086%25%2C%2032.35294117647059%25)&autoCentered=true&clusteringRadius=2&showPictures=true&pitch=0&countriesOpacity=0.2" 
        frameborder="0" className="MapCustom" 
        allow="fullscreen"></iframe>


        </div>

    );
}

export default Map;