import './ryanStyle/style.css';
import Home from './Components/Home';
import Error from './Components/Error';
import About from './Components/About';
import More from './Components/More';
import Portfolio from './Components/Portfolio';
import Contact from './Components/Contact';
import Map from './Components/Map';
import CustomIndex from './Components/CustomIndex';
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="*" element={<Error />} />
      <Route path="/about" element={<About />} />
      <Route path="/port" element={<Portfolio />} />
      <Route path="/more" element={<More />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/map" element={<Map />} />
      <Route path="/new" element={<CustomIndex />} />
    </Routes></Router>
  );
}

export default App;
