import React from 'react';
import '../ryanStyle/about.css'
import '../ryanStyle/style.css';
import '../ryanStyle/iframe.css'
import img1 from '../img/pinkney.jpg';
import img2 from '../img/picture2.JPG';


function About(){

    return (

        <div class="wrapper">
            <div class="sidebar">
                <div class="sidebar-text d-flex flex-column h-100 justify-content-center text-center"><br></br>
                    <img class="mx-auto d-block w-75 bg-primary img-fluid rounded-circle mb-4 p-3" src={img1} alt="" />
                    <h1 class="font-weight-bold">Ryan Pinkney</h1>
                    <p class="mb-4">
                        I have always been passionate about technology because it is fast moving like me. 
                        I am constantly on the go, and I am quick learner. My focus 
                        is to use technology to solve life's problems, both big and small.
                    </p>
                    <div class="d-flex justify-content-center mb-5">
                        <a class="btn btn-outline-primary mr-2" href="https://twitter.com/ryan_pinkney"><i class="fab fa-twitter"></i></a>
                        <a class="btn btn-outline-primary mr-2" href="https://www.facebook.com/ryan.pinkney.1/"><i class="fab fa-facebook-f"></i></a>
                        <a class="btn btn-outline-primary mr-2" href="https://www.linkedin.com/in/ryanpinkney/"><i class="fab fa-linkedin-in"></i></a>
                        <a class="btn btn-outline-primary mr-2" href="https://www.instagram.com/ryan_mr_pinkney/"><i class="fab fa-instagram"></i></a>
                    </div>
                    <a href="https://linktr.ee/pinkney_mr" class="btn btn-lg btn-block btn-primary mt-auto">More Links</a>
                </div>
                <div class="sidebar-icon d-flex flex-column h-100 justify-content-center text-right">
                    <i class="fas fa-2x fa-angle-double-right text-primary"></i>
                </div>
            </div>
            <div class="content">
             
                <div class="container p-0">
                    <nav class="navbar navbar-expand-lg bg-secondary navbar-dark">
                        <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                            <div class="navbar-nav m-auto">
                                <a href="/" class="nav-item nav-link">Home</a>
                                <a href="about" class="nav-item nav-link active">About</a>
                                {/* <a href="port" class="nav-item nav-link">Portfolio</a> */}
                                <a href="more" class="nav-item nav-link">More</a>
                                <a href="contact" class="nav-item nav-link">Contact Me</a>
                            </div>
                        </div>
                    </nav>
                </div>
                <div class="container py-5 px-2 bg-primary">
                <div class="row py-5 px-4">
                    <div class="col-sm-6 text-center text-md-left">
                        <h1 class="mb-3 mb-md-0 text-white text-uppercase font-weight-bold">About Me</h1>
                    </div>
                    <div class="col-sm-6 text-center text-md-right">
                        <div class="d-inline-flex pt-2">
                            <h4 class="m-0 text-white"><a class="text-white" href="/">Home</a></h4>
                            <h4 class="m-0 text-white px-2">/</h4>
                            <h4 class="m-0 text-white">About Me</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container bg-white pt-5">
                <div class="row px-3 pb-5">
                    <div class="col-md-12">
                        <h3 class="mb-4 font-weight-bold">About Me</h3>
                        {/* <img class="img-fluid float-left w-50 mr-4 mb-3" className="Image" src={img2} alt="" /><br></br><br></br> */}
                        <p class="m-0"> I have always been passionate about technology because it is fast moving like me. 
                            I am constantly on the go, and I am quick learner. I particularly love meeting new people 
                            and seeing what experiences they have that I can learn from. I have focused myself on using 
                            technology to solve life's problems, both big and small (especially interested in cybersecurity).</p>
                            <br></br>
                            Whether as an A/V technician or a translator, I look for opportunities to apply my love of 
                            technology and make the system better. I am a problem solver, approaching any situation with 
                            an open mind, positivity, and enthusiasm. 
                            <br></br><br></br>
                            In every situation, I strive to perform my best, learn from others, and use technology to get results.
                            <br></br><br></br>
                            <div class="container">
                                <h3 class="mb-4 font-weight-bold">Education:</h3>
                                <h6>Masters of Information Systems Management</h6>
                                <h6>Bachelor of Science, Information Systems</h6>
                                <h6>Brigham Young University - Marriott School of Business</h6>
                                <h6>Minor: Portuguese</h6>
                                    <ul>
                               
                                        <li>Dean’s List (Marriott School); ALA Conference Presenter 2021</li>
                                        <li>Managing Editor – Marriott Student Review</li>
                                        <li>Member – Phi Eta Sigma, Association for Information Systems, BYU Women’s Health Association</li>
                                    </ul>
                            </div><br></br>

                            <div class="container">
                                <h3 class="mb-4 font-weight-bold">Experience:</h3>
                                <h6>Salesforce System Administrator</h6>
                                <h6>Qualtrics</h6>
                                    <ul>
                                        <li>Developed stakeholder requests for UI, security, and declarative automation</li>
                                        <li>Responded to user requests 20% faster and decreased response times</li>
                                        <li>Contributed to major implementation of Salesforce CPQ package</li>
                                    </ul><br></br>

                                <h6>Computer Programmer</h6>
                                <h6>Brigham Young University – Alumni Relations</h6>
                                    <ul>
                                        <li>Supervised and implemented ColdFusion platform upgrades</li>
                                        <li>Developed new campus hotel booking system, reducing service and maintenance time for developers and end users</li>
                                    </ul><br></br>

                                <h6>Co - Founder</h6>
                                <h6>OCSC Supply Chain Solutions</h6>
                                    <ul>
                                        <li>Developed React solutions ahead of schedule and below cost</li>
                                        <li>Built spreadsheet automation solutions to track expenses and reduce client cost by 15%</li>
                                        <li>Crafted company growth, marketing, and management plan to increase revenue</li>
                                    </ul>

                            <div class="container">
                                <h3 class="mb-4 font-weight-bold">Technical Skills:</h3>
                                    <ul>
                                        <li>Salesforce Certifications: System Administrator, Platform App Builder</li>
                                        <li>Proficient in JavaScript, HTML5, CSS, VBA, Python, ColdFusion, and Django</li>
                                        <li>Experience with AWS, Azure, MySQL, Postgres, Heroku, ServiceNow C#, React, Angular, R, Tidyverse, and Pandas</li>
                                    </ul>
                            </div><br></br>

                            <div class="container">
                                <h3 class="mb-4 font-weight-bold">Other Skills:</h3>
                                    <ul>
                                        <li>Experienced with Meta Business Suite, Libsyn, WordPress, Vercel, and DMX Lighting</li>
                                    </ul>
                            </div>
                            <br></br>
                        {/* <div class="text-center">    <a href="https://drum.io/mr_ryanpinkney">https://drum.io/mr_ryanpinkney </a></div><br></br> */}
                    </div>
                    <div class="container py-4 bg-secondary text-center">
                        <p class="m-0 text-white">
                            &copy; <a class="text-white font-weight-bold" href="index.html">Ryan Pinkney</a>. All Rights Reserved.
                        </p>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default About;