import React from 'react';
import '../ryanStyle/style.css';
import img1 from '../img/pinkney.jpg';



function Contact(){

    return (

        <div class="wrapper">
            <div class="sidebar">
                <div class="sidebar-text d-flex flex-column h-100 justify-content-center text-center"><br></br>
                    <img class="mx-auto d-block w-75 bg-primary img-fluid rounded-circle mb-4 p-3" src={img1} alt="" />
                    <h1 class="font-weight-bold">Ryan Pinkney</h1>
                    <p class="mb-4">
                        I have always been passionate about technology because it is fast moving like me. 
                        I am constantly on the go, and I am quick learner. My focus 
                        is to use technology to solve life's problems, both big and small.
                    </p>
                    <div class="d-flex justify-content-center mb-5">
                        <a class="btn btn-outline-primary mr-2" href="https://twitter.com/ryan_pinkney"><i class="fab fa-twitter"></i></a>
                        <a class="btn btn-outline-primary mr-2" href="https://www.facebook.com/ryan.pinkney.1/"><i class="fab fa-facebook-f"></i></a>
                        <a class="btn btn-outline-primary mr-2" href="https://www.linkedin.com/in/ryanpinkney/"><i class="fab fa-linkedin-in"></i></a>
                        <a class="btn btn-outline-primary mr-2" href="https://www.instagram.com/ryan_mr_pinkney/"><i class="fab fa-instagram"></i></a>
                    </div>
                    <a href="https://linktr.ee/pinkney_mr" class="btn btn-lg btn-block btn-primary mt-auto">More Links</a>
                </div>
                <div class="sidebar-icon d-flex flex-column h-100 justify-content-center text-right">
                    <i class="fas fa-2x fa-angle-double-right text-primary"></i>
                </div>
            </div>
            <div class="content">
             
                <div class="container p-0">
                    <nav class="navbar navbar-expand-lg bg-secondary navbar-dark">
                        <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                            <div class="navbar-nav m-auto">
                                <a href="/" class="nav-item nav-link">Home</a>
                                <a href="about" class="nav-item nav-link">About</a>
                                {/* <a href="port" class="nav-item nav-link">Portfolio</a> */}
                                <a href="more" class="nav-item nav-link">More</a>
                                <a href="contact" class="nav-item nav-link active">Contact Me</a>
                            </div>
                        </div>
                    </nav>
                </div>

                <div class="container py-5 px-2 bg-primary">
                <div class="row py-5 px-4">
                    <div class="col-sm-6 text-center text-md-left">
                        <h1 class="mb-3 mb-md-0 text-white text-uppercase font-weight-bold">Contact Me</h1>
                    </div>
                    <div class="col-sm-6 text-center text-md-right">
                        <div class="d-inline-flex pt-2">
                            <h4 class="m-0 text-white"><a class="text-white" href="/">Home</a></h4>
                            <h4 class="m-0 text-white px-2">/</h4>
                            <h4 class="m-0 text-white">Contact Me</h4>
                        </div>
                    </div>
                </div>
            </div>

                <div class="container bg-white pt-5">
                <div class="row px-3 pb-2">
                    {/* <div class="col-sm-4 text-center mb-3">
                        <i class="fa fa-2x fa-map-marker-alt mb-3 text-primary"></i>
                        <h4 class="font-weight-bold">Location</h4>
                        <p>Salt Lake City, Utah</p>
                    </div> */}
                    <br></br><br></br>
                    <div class="col-sm-4 text-center mb-3">
                        <i class="far fa-2x fa-envelope mb-3 text-primary"></i>
                        <h4 class="font-weight-bold">Message</h4>
                        <p>Find me on LinkedIn</p>
                    </div>
                    <br></br><br></br>
                    {/* <div class="col-sm-4 text-center mb-3">
                        <i class="fa fa-2x fa-phone-alt mb-3 text-primary"></i>
                        <h4 class="font-weight-bold">Phone</h4>
                        <p></p>
                    </div> */}
                </div>

                <br></br>
{/* 
                <iframe title="ContactForm" src="https://docs.google.com/forms/d/e/1FAIpQLScOYBY7AuVy_VMXXmPY6wxvzE_peMI-aoUQYiPCEj1Qn5Ek2Q/viewform?embedded=true" 
                width="640" height="964" frameborder="0" marginheight="0" marginwidth="0" class="text-center">Loading…</iframe> */}

                </div>
         
            

                    
                
  
                <div class="container py-4 bg-secondary text-center">
                    <p class="m-0 text-white">
                        &copy; <a class="text-white font-weight-bold" href="index.html">Ryan Pinkney</a>. All Rights Reserved.
                    </p>
                </div>
          
                </div>
            </div>



    );
}

export default Contact;