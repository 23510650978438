import React from 'react';
import '../ryanStyle/style.css';
import '../ryanStyle/portfolio.css';
import img1 from '../img/pinkney.jpg';
import img2 from '../img/port1.png';
import img3 from '../img/port2.png';
import img4 from '../img/port3.png';
import img5 from '../img/port4.png';
import img6 from '../img/port5.png';
import img7 from '../img/port6.png';
import img8 from '../img/port7.png';
import img9 from '../img/port8.png';




function Portfolio(){

    return (

        <div class="wrapper">
            <div class="sidebar">
                <div class="sidebar-text d-flex flex-column h-100 justify-content-center text-center"><br></br>
                    <img class="mx-auto d-block w-75 bg-primary img-fluid rounded-circle mb-4 p-3" src={img1} alt="" />
                    <h1 class="font-weight-bold">Ryan Pinkney</h1>
                    <p class="mb-4">
                        I have always been passionate about technology because it is fast moving like me. 
                        I am constantly on the go, and I am quick learner. My focus 
                        is to use technology to solve life's problems, both big and small.
                    </p>
                    <div class="d-flex justify-content-center mb-5">
                        <a class="btn btn-outline-primary mr-2" href="https://twitter.com/ryan_pinkney"><i class="fab fa-twitter"></i></a>
                        <a class="btn btn-outline-primary mr-2" href="https://www.facebook.com/ryan.pinkney.1/"><i class="fab fa-facebook-f"></i></a>
                        <a class="btn btn-outline-primary mr-2" href="https://www.linkedin.com/in/ryanpinkney/"><i class="fab fa-linkedin-in"></i></a>
                        <a class="btn btn-outline-primary mr-2" href="https://www.instagram.com/ryan_mr_pinkney/"><i class="fab fa-instagram"></i></a>
                    </div>
                    <a href="https://drum.io/mr_ryanpinkney" class="btn btn-lg btn-block btn-primary mt-auto">More Links</a>
                </div>
                <div class="sidebar-icon d-flex flex-column h-100 justify-content-center text-right">
                    <i class="fas fa-2x fa-angle-double-right text-primary"></i>
                </div>
            </div>
            <div class="content">
             
                <div class="container p-0">
                    <nav class="navbar navbar-expand-lg bg-secondary navbar-dark">
                        <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                            <div class="navbar-nav m-auto">
                                <a href="/" class="nav-item nav-link">Home</a>
                                <a href="about" class="nav-item nav-link">About</a>
                                <a href="port" class="nav-item nav-link active">Portfolio</a>
                                <a href="more" class="nav-item nav-link">More</a>
                                <a href="contact" class="nav-item nav-link">Contact Me</a>
                            </div>
                        </div>
                    </nav>
                </div>
         
                
            
                <div class="container py-5 px-2 bg-primary">
                <div class="row py-5 px-4">
                    <div class="col-sm-6 text-center text-md-left">
                        <h1 class="mb-3 mb-md-0 text-white text-uppercase font-weight-bold">Portfolio</h1>
                    </div>
                    <div class="col-sm-6 text-center text-md-right">
                        <div class="d-inline-flex pt-2">
                            <h4 class="m-0 text-white"><a class="text-white" href="/">Home</a></h4>
                            <h4 class="m-0 text-white px-2">/</h4>
                            <h4 class="m-0 text-white">Portfolio</h4>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container bg-white pt-5">
                <div class="row px-3 pb-5">
                    <div class="col-md-12">

                    <h2 class="mb-4 font-weight-bold">ASP.NET</h2>
                     
                     <ul>
                         <li>Your Everyday Efficient Transportation Services (inactive)</li><br></br>
                         <img class="w-100" className="ImageFormat" src={img2} alt="" /><br></br>
                         <br></br>
                         <img class="w-100" className="ImageFormat" src={img3} alt="" /><br></br>
                         <br></br>
                         <img class="w-100" className="ImageFormat" src={img4} alt="" /><br></br>
                         <br></br>
                         <img class="w-100" className="ImageFormat" src={img5} alt="" /><br></br>
                         <br></br>
                         <img class="w-100" className="ImageFormat" src={img6} alt="" />
                         <br></br> 
                     </ul><br></br>

   
                     <h3 class="mb-4 font-weight-bold">HTML5, CSS</h3>
                     <ul>
                         <a href="https://snowglobeturtle.vercel.app/">Snow Globe Turtle (active)</a>
           
                     </ul><br></br>

                     <h2 class="mb-4 font-weight-bold">Node.js</h2>
      
                     <ul>
                         
                         <li>RANT Records (inactive)</li><br></br>
                         <img class="w-100" className="ImageFormat" src={img7} alt="" />
                         <br></br> 
                         <img class="w-100" className="ImageFormat" src={img8} alt="" />
                         <br></br>
                         <img class="w-100" className="ImageFormat" src={img9} alt="" />
                     </ul><br></br>
     
   


                
                         <h3 class="mb-4 font-weight-bold">WordPress</h3>
                             <ul>
                                 <a href="https://marriottstudentreview.org/">Marriott Student Review (active)</a>
                     
                   
                             </ul>




             <br></br>
              <div class="text-center">    <a href="https://github.com/RexforNext22">See more on GitHub</a></div><br></br>

     </div><br></br>

                       


            
                
  
                <div class="container py-4 bg-secondary text-center">
                    <p class="m-0 text-white">
                        &copy; <a class="text-white font-weight-bold" href="index.html">Ryan Pinkney</a>. All Rights Reserved.
                    </p>
                </div>
            </div>
        </div>
    </div>
    </div>

 
          

      
    
   


    );
}

export default Portfolio;