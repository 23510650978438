import React from 'react';
import '../ryanStyle/CustomIndex.css'

function CustomIndex(){

    return (
        <div>
            <h6 class="center">Custom Home</h6>
        </div>

    );
}

export default CustomIndex;